/*
Template Name:   - Responsive Bootstrap 4 Admin Dashboard
Author: Themesdesign
Contact: themesdesign.in@gmail.com
File: Main Css File
*/

/* TABLES OF CSS

1. General
2. Topbar
3. Page Header
4. Footer
5. Right Sidebar
6. Vertical
7. Horizontal Nav
8. Layouts
9. Waves
10. Avatar
11. Helper
12. Demos
13. Plugins
14. Authentication
15. Email
16. Coming Soon
17. Timeline
18. Extra Pages

*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/round-slider";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/datatable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "~antd/dist/antd.css";

// page Loader
.main_loader_container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.8);
  flex-direction: column;

  .loading_title {
    font-size: 20px;
    font-weight: bold;
    position: relative;
    top: -20px;
  }

  .loader_logo {
    width: 40px;
  }
}

// for loader animation
@keyframes ldio-hpfsdhpdhyw {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

body {
  font-family: "Inter" !important;
}

.ldio-hpfsdhpdhyw div {
  left: 93px;
  top: 53px;
  position: absolute;
  animation: ldio-hpfsdhpdhyw linear 1s infinite;
  background: #000099;
  width: 14px;
  height: 14px;
  border-radius: 7px / 7px;
  transform-origin: 7px 47px;
}

.ldio-hpfsdhpdhyw div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(2) {
  transform: rotate(36deg);
  animation-delay: -0.8s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(3) {
  transform: rotate(72deg);
  animation-delay: -0.7s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(4) {
  transform: rotate(108deg);
  animation-delay: -0.6s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(5) {
  transform: rotate(144deg);
  animation-delay: -0.5s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(6) {
  transform: rotate(180deg);
  animation-delay: -0.4s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(7) {
  transform: rotate(216deg);
  animation-delay: -0.3s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(8) {
  transform: rotate(252deg);
  animation-delay: -0.2s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(9) {
  transform: rotate(288deg);
  animation-delay: -0.1s;
  background: #000099;
}

.ldio-hpfsdhpdhyw div:nth-child(10) {
  transform: rotate(324deg);
  animation-delay: 0s;
  background: #000099;
}

.loadingio-spinner-spinner-bql8n33rdvh {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}

.ldio-hpfsdhpdhyw {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ldio-hpfsdhpdhyw div {
  box-sizing: content-box;
}

.ant-picker-range {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// Student Details page
.student_detailsPage {
  display: flex;

  .student_image_wrap {
    display: block;
  }

  .student_details_body {
    width: 100%;
    padding-left: 20px;
  }
}

.img_wrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 5px solid #eeeeee;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.bg-instagram {
  background-color: #3f729b;
}

.bg-linkedin {
  background-color: #007bb6;
}

.bg-facebook {
  background-color: #3b5998;
}

.bg-twitter {
  background-color: #55acee;
}

.card-header {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.2rem !important;
    margin-bottom: 0px;
  }

  .row {
    align-items: center;
  }
}

.dropzone_wrapper {
  padding: 10px;
  background: #f0f0f1 !important;
  overflow: hidden;
  border-radius: 30px;
  border: 1px solid #dddddd;
  margin-bottom: 30px;
  margin-top: 30px;

  .dropZone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: none !important;

    p {
      margin: 0px;
    }
  }
}

.progress_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .progress_wrap {
    width: 100px;
  }
}

.document-uploader-wrapper {
  padding: 10px 15px;
  margin-bottom: 20px;
  background: #f9f9f9;
  overflow: hidden;
  border-radius: 5px;
}

.react-tagsinput-tag {
  background-color: $sidebar-dark-bg !important;
  color: #ffffff !important;
  border-color: $sidebar-dark-bg !important;
  border-radius: 5px !important;
}

.image-uploader {
  position: relative;
  width: 260px;
  height: 180px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #f7f7f7;

  .image-uploader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image_upload {
    width: 100%;
    min-height: 180px;
  }

  .image_upload_placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #eeeeee;

    i {
      &.la,
      &.ri-add-box-line {
        font-size: 50px;
        opacity: 0.1;
      }
    }

    .image_upload_titler {
      font-size: 20px;
      opacity: 0.3;
    }

    .image_upload_file {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 99;
      cursor: pointer;
    }
  }
}

.courseWrap {
  background: white;
  overflow: hidden;
  background: #ffffff;
  border: 3px solid #f0f0f1;
  padding: 0px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;

  .course_image {
    width: 100%;
    position: relative;
    height: 20vh;
    overflow: hidden;
    border-radius: 10px;

    .course_status {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 99;
      padding: 5px 15px;
      border-radius: 5px;
      color: #ffffff;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

      &.unPublished {
        background: #ff0000;
      }

      &.published {
        background: #51db51;
      }
    }

    img {
      width: 100%;
      min-height: 210px;
    }

    .discount_tag {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 99;
      display: flex;
      line-height: 0.95;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      color: #ffffff;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      font-size: 20px;
      font-weight: bold;
      font-style: italic;
      text-align: center;
    }
  }

  .course_content {
    padding: 10px 15px;

    .courseTitle {
      color: #212121;
      font-size: 18px;
      display: block;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .sections_details_link {
      color: #999999;
    }

    .course_validity_link {
      color: #616161;
      // border-bottom: 1px solid #eeeeee;
      padding-bottom: 5px;
    }

    .course_price {
      font-weight: bold;
      color: #3dac24;

      .price-cut {
        color: #cccccc;
        font-weight: normal;
        text-decoration: line-through;
        font-size: 12px;
      }
    }
  }

  .course_content_footer {
    border-top: 1.6px solid #f0f0f1;
    padding: 5px;
    padding-bottom: 0px;
    margin-top: 10px;
    display: flex;

    .footer_link_11 {
      color: #c4c4c4;
      font-size: 18px;
      font-weight: bold;
      padding: 2px 15px;
      border-right: 1px solid #f0f0f1;

      &:last-child {
        border-right: none;
      }
    }
  }
}

// for pagination
.pagination {
  li {
    min-width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid #eeeeee;
    margin-left: 5px;

    a {
      color: #dddddd;
      font-weight: bold;
    }

    &.active {
      border-color: #000000;

      a {
        color: #000000;
      }
    }
  }
}

.justify-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.img_upld_wrapper {
  position: relative;

  .upload_img_close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  }
}

.image_upload_button {
  position: relative;

  .btn_upload_file {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 99;
    opacity: 0;
    cursor: pointer;
  }
}

.section_title {
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  font-size: 16px;
}

.live_class_wrapper {
  background: #f9f9f9;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.starImportnat {
  color: red;
  position: relative;
  left: -2px;
}

.react-datepicker-wrapper {
  display: block !important;
  width: 100%;
  padding: 0.75rem 1rem !important;
  font-size: 1rem;
  line-height: 1.25;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #cacfe7 !important;
  color: #3b4781;

  .react-datepicker__input-container {
    display: block !important;

    input {
      display: block !important;
      width: 100%;
      border: none;
    }
  }
}

.delete-button {
  color: #ff394f !important;
  font-weight: bold !important;
}

.course_content_sidebar {
  height: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 5px;

  .sidebar_title {
    background: $sidebar-dark-bg;
    padding: 10px 15px;
    color: #ffffff !important;
    margin: 0px;
    font-size: 15px;
  }

  .sidebar_body {
    padding: 15px 10px;

    .content_section {
      margin-bottom: 10px;
      border-radius: 4px;
      overflow: hidden;

      .section-title {
        background: #f2f2f2;
        padding: 10px;
        position: relative;
        padding-right: 50px;
        font-weight: bold;
        font-size: 13px;

        .circle {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
        }

        .delete_section {
          position: absolute;
          right: 33px;
          top: 10px;
          cursor: pointer;
          color: #ff394f;
        }

        .edit_section {
          position: absolute;
          right: 60px;
          top: 10px;
          cursor: pointer;
          color: #21ad49;
        }
      }

      .section-body {
        padding: 10px 5px;
        background: #f9f9f9;
      }
    }
  }
}

.section_add_wrapper {
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 5px;

  .section-header {
    padding: 10px 15px;
    background: #ff394f;

    h4 {
      color: #ffffff;
      margin: 0px;
    }
  }
}

.add_options_wrapper {
  .btn {
    &.active {
      background: #ff394f;
      color: #ffffff !important;
    }
  }
}

.section_items_wrapper {
  .section_item {
    position: relative;
    margin-bottom: 10px;
    padding: 10px 15px;
    background: #f1f1f1;
    overflow: hidden;
    border-radius: 5px;
    padding-right: 150px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .section_icon {
      margin-right: 10px;
    }

    .section_remove {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;

      i {
        color: #ff394f;
      }
    }
  }
}

.item_row {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-bottom: 1px solid #eeeeee;
  position: relative;

  i {
    font-size: 25px !important;
  }
}

.item_preview_wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    font-size: 12px;
    margin: 0px;
    margin-right: 10px;
    opacity: 0.3;
    font-weight: bold;
  }
}

.preview_btn {
  position: absolute;
  right: 5px;
  top: 0px;
}

.document-uploader-wrapper {
  padding: 10px 15px;
  margin-bottom: 20px;
  background: #f9f9f9;
  overflow: hidden;
  border-radius: 5px;
}

.sectionItems_wrapper {
  margin-bottom: 20px;

  .section_item_row {
    margin-bottom: 10px;
    position: relative;
    border-radius: 5px;
    padding: 15px 10px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    border-left-width: 10px;
    border-left-style: solid;
    border-left-color: #ff394f;

    .row_icon {
      position: relative;
      top: 2px;
      margin-right: 10px;

      i {
        font-size: 2.5rem;
      }
    }

    .section_remove {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      i {
        color: #ff394f;
        font-size: 28px;
      }
    }
  }

  .section_joint {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

// quiz_card_wrapper
.quiz_card_wrapper {
  background: #ffffff;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 20px;
  display: block;

  .quiz_category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }

  .quiz_name {
    height: 150px;

    .title {
      font-size: 1.5rem;
      line-height: 1.25;
      font-weight: 500;
      color: #000000;
    }
  }

  .quiz_category_text {
    color: #86a1ae;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .category_wrap {
    background: #f1f1f1;
    padding: 3px 10px;
    border-radius: 3px;
  }
}

// questions_wrapper on edit quiz page
.questions_wrapper {
  .question_card {
    position: relative;
    background: #ffffff;
    padding: 15px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-bottom: 20px;
    display: block;

    .card_header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }

    .card_options {
      position: relative;

      .que_row {
        padding: 10px 15px;
        margin-bottom: 10px;
        border: 1px solid #09d3f1;
        background: rgba(9, 211, 241, 0.05);
        border-radius: 10px;
        position: relative;

        &.active {
          border: 1px solid #26cd05;
          background: rgba(38, 205, 5, 0.2);
          padding-right: 30px;

          .currect_icon {
            position: absolute;
            right: 10px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            border-radius: 50%;
            color: #26cd05;
            top: 50%;
            transform: translateY(-50%);
            -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}

.space-between {
  justify-content: space-between;
  align-items: center;
}

.section_title {
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.add_options_wrapper {
  .btn,
  .btn-secondary {
    &.active {
      background-color: #ff394f !important;
      border-color: #ff394f !important;
      color: #ffffff !important;
    }
  }
}

.filters {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 10px;
}

.custom_switch_course {
  position: relative;
  background: #ffffff;
  border-radius: 15px;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .customButton {
    width: 50%;
    border-radius: 15px;
    min-height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $sidebar-dark-bg;
    background: #ffffff;
    cursor: pointer;

    &.active {
      background: $sidebar-dark-bg;
      color: #ffffff;
    }
  }
}

.search_filter {
  position: relative;

  .clear_search_text {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    i {
      font-size: 13px;
    }
  }
}

.ant-select {
  &.isError {
    .ant-select-selector {
      border-color: red !important;
    }
  }
}

.isError {
  color: red;
}

// blog card
.blog_card_wrapper {
  background: white;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 0px;
  width: 100%;
  border-radius: 10px;
  min-height: 100px;
  position: relative;
  margin: 15px 0;

  .blog_card_image {
    height: 210px;
    overflow: hidden;
    position: relative;

    .card_image_overlay {
      height: 0px;
      width: 100%;
      left: 0px;
      bottom: 0px;
      overflow: hidden;
      position: absolute;
      transition: all 0.4s ease-in-out;
      background: rgba(0, 0, 0, 0.4);
      z-index: 99;

      .card_overlay_inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .btn-outline {
          padding: 5px 15px;
          background: transparent;
          text-align: center;
          color: #ffffff;
          border: 1px solid #ffffff;
          transition: all 0.4s ease-in-out;

          &:hover {
            background: #ffffff;
            color: #000000;
          }

          i {
            position: relative;
            top: 1px;
            margin-right: 0px;
            font-size: 16px;
          }
        }
      }
    }

    &:hover {
      .card_image_overlay {
        height: 100%;
      }
    }

    .blog_status {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 99;
      padding: 5px 15px;
      border-radius: 5px;
      color: #ffffff;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

      &.unPublished {
        background: #ff0000;
      }

      &.published {
        background: #51db51;
      }
    }

    img {
      width: 100%;
      min-height: 210px;
    }
  }

  .blog_card_content {
    padding: 10px 15px;

    .blog_card_title {
      color: #666666;
      font-size: 18px;
      display: block;
      font-weight: 700;
    }

    .blog_card_description {
      margin: 5px 0;
      min-height: 70px;

      * {
        display: inline;
        font-size: 14px !important;
        line-height: 1.4 !important;
        font-weight: normal !important;

        br {
          display: none !important;
        }
      }
    }

    .card_publish_date {
      margin: 5px 0;
    }

    .blog_card_tags {
      border-top: 1px solid #eeeeee;
      padding-top: 10px;
      margin-top: 10px;
      height: 40px;
      overflow: hidden;

      .chips {
        background: #f1f1f1;
        margin-right: 5px;
        padding: 3px 10px;
        display: inline-block;
        margin-bottom: 5px;
        font-size: 11px;
        border-radius: 15px;
      }
    }
  }
}
.chips {
  background: #f1f1f1;
  margin-right: 5px;
  padding: 3px 10px;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 11px;
  border-radius: 15px;
}

.DayPickerInput {
  display: block !important;

  input {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.25;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid #cacfe7;
    color: #3b4781;
  }
}

.DayPicker-Day--highlighted {
  background-color: orange !important;
  color: white;
}

.form_row {
  display: flex;

  .icon_wrap {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right: -2px;
    position: relative;
    z-index: 9;

    &.facebook {
      background: #1876f2;
    }

    &.twitter {
      background: #50abf1;
    }

    &.instagram {
      background: #d6249f;
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    }

    &.telegram {
      background: #1e96c8;
    }

    &.linkedin {
      background: #0077b5;
    }

    &.youtube {
      background: #ff0000;
    }

    &.whatsapp {
      background: #06d755;
    }

    i {
      font-size: 24px;
      color: #ffffff;

      &.rotate {
        transform: rotate(-40deg);
      }
    }
  }
}

.ck-editor__editable_inline {
  min-height: 250px;
}

.add_images_wrapper {
  margin-bottom: 20px;

  .img_row {
    position: relative;
    padding: 10px 15px;
    background: $sidebar-dark-bg;
    border-radius: 10px;
    padding-right: 40px;
    margin-bottom: 10px;

    span {
      color: #ffffff;
      opacity: 0.6;

      &.copy {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .isTooltip {
          margin-left: 5px;
        }
      }
    }
  }
}

.tabs-wrapper {
  background: #ffffff;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .tab {
    width: 100%;
    text-align: center;
    cursor: pointer;
    padding: 10px 15px;
    font-weight: bold;
    color: #444444;
    transition: all 0.4s ease;
    white-space: nowrap;

    &.active {
      border-color: #000073 !important;
      color: #ffffff;
      background: #000073;
    }
  }
}

.tabs_form {
  padding: 20px 0px;
}

.w-full {
  width: 100% !important;
}

.opt_input {
  input[type="text"] {
    width: 100% !important;
    height: 3rem;
    padding: 0.75rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1.45;
    border-radius: 0.35rem;
    font-size: 30px !important;
    border: 1px solid #cacfe7;
    color: #3b4781;
    margin: 0 5px;

    &:focus {
      color: #4e5154;
      background-color: #fff;
      border-color: #1e9ff2;
      outline: 0;
      box-shadow: none;
    }
  }
}

.logo_wrappers {
  display: flex;
  flex-direction: row;

  .logo_box {
    margin-right: 20px;
  }
}

// * welcome-note-wrapper
.welcome-note-wrapper {
  position: fixed;
}

// * bundle-card-wrapper
.bundle-card-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;

  .image {
    height: 220px;
    overflow: hidden;
    position: relative;

    .card_image {
      width: 100%;
      min-height: 220px;
    }

    .publish_tag {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 999;
      padding: 5px 15px;
      border-radius: 5px;
      color: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

      &.active {
        background: #51db51;
      }

      &.disabled {
        background: #ff0000;
      }
    }

    .card_image_overlay {
      height: 0px;
      width: 100%;
      left: 0px;
      bottom: 0px;
      overflow: hidden;
      position: absolute;
      transition: all 0.4s ease-in-out;
      background: rgba(0, 0, 0, 0.4);
      z-index: 99;

      .card_image_float {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        display: flex;
        z-index: 999;
        justify-content: center;
        align-content: flex-end;
        padding: 10px;
        flex-wrap: wrap;
        flex-direction: column;

        .btn {
          i {
            color: #ffffff;
          }

          &:hover {
            i {
              color: $gray-900;
            }
          }
        }
      }
    }

    &:hover {
      .card_image_overlay {
        height: 100%;
      }
    }
  }

  .card_content {
    padding: 10px;

    .card-title {
      font-weight: bold;
      color: $gray-700;
      font-size: 18px;
    }
  }

  &.disabled {
    .card_content {
      background-color: $gray-300;
    }
  }
}

.section_loading {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 22px;
  }
}

.spin {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message-list li .col-mail-1 .title {
  position: relative;
  left: 30px;
  white-space: normal;
  display: block;
  padding-right: 30px;
  line-height: normal;
  padding-top: 10px;
}

.email-chip {
  background: #f1f1f1;
  padding: 4px 15px;
  display: inline-block;
  margin: 5px;
  border-radius: 20px;
}

.email_sent {
  max-height: 200px;
  overflow-y: auto;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.rdw-editor-main {
  padding: 15px;
}

.email-table {
  .ant-table-cell {
    padding: 0px !important;
  }
}

.upload_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
}

.custom_selector {
  width: 100%;

  .ant-select-selector {
    border-color: #ced4da !important;
    display: block;
    height: calc(1.5em + 0.94rem + 2px) !important;
    padding: 0.47rem 0.75rem !important;
    font-size: 0.9rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    border-radius: 0.25rem !important;

    .ant-select-selection-placeholder {
      top: -3px;
      position: relative;
    }
  }
}

.card {
  border-radius: 10px;
  overflow: hidden;

  .card-header {
    background-color: $sidebar-bg;
    border-bottom: 1px solid #eeeeee;
  }

  .card-body {
    overflow: auto;
  }
}

.courseWrap {
  background: white;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 0px;
  width: 100%;
  border-radius: 10px;
  min-height: 100px;
  position: relative;
  margin: 15px 0;

  .course_image {
    width: 100%;
    position: relative;
    height: 20vh;
    overflow: hidden;

    .course_status {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 99;
      padding: 5px 15px;
      border-radius: 5px;
      color: #ffffff;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

      &.unPublished {
        background: #ff0000;
      }

      &.published {
        background: #51db51;
      }
    }

    img {
      width: 100%;
      min-height: 210px;
    }

    .discount_tag {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 99;
      display: flex;
      line-height: 0.95;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      color: #ffffff;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      font-size: 20px;
      font-weight: bold;
      font-style: italic;
      text-align: center;
    }
  }

  .course_content {
    padding: 10px 15px;

    .courseTitle {
      color: #212121;
      font-size: 18px;
      display: block;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .sections_details_link {
      color: #999999;
    }

    // .course_validity_link {
    //   color: #999999;
    //   border-bottom: 1px solid #eeeeee;
    //   padding-bottom: 5px;
    //   margin-bottom: 5px;
    // }

    .course_price {
      font-weight: bold;
      color: #3dac24;

      .price-cut {
        color: #cccccc;
        font-weight: normal;
        text-decoration: line-through;
        font-size: 12px;
      }
    }
  }
}

.course_video_placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  position: relative;
  width: 260px;
  height: 180px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #f7f7f7;
  cursor: pointer;

  .place_icon {
    font-size: 60px;
    opacity: 0.1;
  }

  .image_upload_titler {
    font-size: 20px;
    opacity: 0.3;
  }
}

.vIcon_wrap {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: -2px;
  position: relative;
  z-index: 999;

  &.youtube {
    background: #ff0000;
  }

  &.vimeo {
    background: #33b3f2;
  }

  &.custom {
    background: #1a2e3b;
  }

  &.upload {
    background: #65cb3d;
  }

  i {
    color: #ffffff;
    font-size: 30px !important;
  }
}

.videoLink_wrapper {
  padding: 30px 0px;

  .video_link_input {
    text-align: center;
    font-size: 18px;
    height: 50px;
    border: 2px solid #eeeeee;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    font-weight: 300;
    font-style: italic;
    transition: all 0.4s ease-in-out;

    &:focus {
      border-color: #33b3f2;
      outline: none;
      box-shadow: none;
    }
  }
}

.dropzone_wrapper {
  padding: 10px;
  background: #f9f9f9;
  overflow: hidden;
  border-radius: 10px;
  position: relative;

  .dropZone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed 2px #ccc;
    padding: 20px;

    p {
      margin: 0px;
    }
  }
}

.progress_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .progress_wrap {
    width: 100px;
  }
}

.layout_change_icon {
  font-size: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.6;
  line-height: 1.2;

  &:hover {
    opacity: 1;
  }
}

.layout_thumbnail_view {
  display: flex;
  flex-wrap: wrap;

  .list_component_wrapper {
    width: calc(100% / 4 - 20px);
    padding: 7px;
    margin: 10px;
    background-color: #f7f7f7;
    border-radius: 10px;
    border: 1px solid #cccccc;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
    position: relative;
    cursor: pointer;

    .more_button {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 999;
      font-weight: bold;
      font-size: 16px;
      padding: 1px 10px;
      border-radius: 5px;
      width: 25px;
      height: 25px;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .custom_new_drop_down {
      position: absolute;
      right: 5px;
      top: 35px;
      background: #fff;
      z-index: 999;
      padding: 10px;
      border-radius: 10px;
      min-width: 150px;

      .dropdown-item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 10px;

        i {
          margin-right: 5px;
        }
      }
    }

    &:hover {
      border: 1px solid #33b3f2;
    }

    img {
      // max-width: 100%;
      height: 185px;
      width: 100%;
    }

    .icon_big_ff {
      font-size: 40px;
      color: #444444;
      opacity: 0.6 !important;
    }

    .component_selected {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 2px solid #33b3f2;
      border-radius: 10px;

      .checked_icon {
        color: #06d755;
        position: relative;
        left: 5px;
        top: 0px;
        font-size: 30px;
      }
    }
  }
}

.component_body {
  padding: 10px 5px;

  .component_body_title {
    font-size: 14px;
    font-weight: 700;
    word-break: break-all;
  }
}

.box_icon_media {
  width: 100%;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  background-color: #f2f2f2;
  // border: 1px solid #cccccc;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.layout_list_view {
  display: block;
  width: 100%;

  .list_component_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
    transition: all 0.3s ease-in-out;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }

    .box_icon_media {
      width: 100px;
      height: 100px;

      img {
        max-width: 100%;
      }
    }

    .component_body {
      padding-left: 15px;
    }

    .component_selected {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 2px solid #33b3f2;
      border-radius: 10px;

      .checked_icon {
        color: #06d755;
        position: relative;
        left: 5px;
        top: 0px;
        font-size: 30px;
      }
    }
  }
}

.pg-viewer-wrapper * {
  height: auto !important;
  overflow: auto !important;
}

.filter_options {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  margin-top: 15px;

  .form-control {
    background-color: #f0f0f1;
    border-radius: 30px !important;
    border-color: #f0f0f1 !important;
  }
}

#page-topbar {
  box-shadow: none !important;
  background: #fafafa !important;
}

// .left_special_border{
//   position: relative;
//   z-index: 9;
//   &:before{
//     content: "";
//     position: absolute;
//     background: #212121;
//     -webkit-border-top-left-radius: 30px;
//     -moz-border-radius-topleft: 30px;
//     border-top-left-radius: 30px;
//     width: 150px;
//     height: 150px;
//   }
// }

.ssdd {
  font-weight: bold !important;
  color: #000000 !important;
}

// .page-content{
//   padding-left: 70px;
//   padding-right: 70px;
// }
// .page-content > .container-fluid{
//   padding: 0px !important;
// }
// .card{
//   background: transparent !important;
// }

.page-content > .container-fluid {
  padding: 0px !important;
}

.close_icon_dropzone {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #c4c4c4;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
}

.ppii {
  position: relative;
  padding-right: 35px;
  height: calc(100% - 70px) !important;

  &::after {
    position: absolute;
    content: "";
    background: #fafafa;
    width: 30px;
    height: calc(100% + 70px) !important;
    top: 0px;
    right: 0;
    -webkit-border-top-left-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
}

.main-content > .container-fluid {
  padding: 0px !important;
}

.btn {
  font-weight: 700 !important;
  font-size: 16px !important;
  border-radius: 6px !important;
  padding: 10px 20px 10px 20px !important;
}

h4 {
  font-weight: bold !important;
  font-size: 24px !important;
}

.custom-btn {
  padding: 5px 10px !important;
  border-radius: 5px;
  border-width: 1px !important;
}

.course_column_wrapper {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin-left: -30px;
  margin-right: -30px;

  .course_column {
    width: 33.33%;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
  }
}

.back_btn,
.back_btn > a {
  display: flex;
  align-items: center;
  color: #c4c4c4;
  font-weight: bold;
}

.card {
  background: #ffffff;
  border: 3px solid #f0f0f1;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: none;

  .card-body {
    padding: 35px;
  }
}

.section_title_page {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}

.course_form_wrapper {
  .form-control {
    background: #ffffff;
    border: 1.6px solid #f0f0f1;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .ant-space {
    display: block;
  }
}

.course_image_uploader {
  height: 130px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
  background-color: #f0f0f1;
  margin-bottom: 3px;

  img {
    width: 100%;
  }

  .dropZone {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .uploader_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    opacity: 0.4;
  }

  .close_icon {
    position: absolute;
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    top: 10px;

    i {
      position: relative;
      top: 2px;
    }
  }

  .upload_progress {
    position: absolute;
    top: 2px;
    left: 3px;
    height: 10px;
    width: calc(100% - 6px);
    background-color: #ffffff;
    border-radius: 30px;
    padding: 2px;
    position: relative;
    overflow: hidden;
  }

  .image_options_upload {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 60px;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    background: rgba(120, 74, 158, 0.8);
    border-radius: 50%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    .upload_inner_icon {
      font-size: 50px;
      color: #ffffff;
    }

    .upload_inner_content {
      color: #ffffff;
      font-size: 13px;
      margin-top: -10px;
    }
  }

  &:hover {
    .image_options_upload {
      opacity: 1;
    }
  }
}

// ! build_course_wrapper
.build_course_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #212121;
  z-index: 9999;
  animation-name: exampleWidth;
  animation-duration: 0.4s;
  display: flex;
  overflow: auto;

  .left_section {
    width: 550px;
    height: 100%;

    .inner_content_sidebar {
      padding: 0 1.5rem;

      .featured_image {
        border: 3px solid #f0f0f1;
        box-sizing: border-box;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-top: 5rem;
      }
    }

    .add_element_trigger {
      color: #4caf50;
      display: flex;
      font-size: 18px;
      font-weight: bold;
      align-items: center;
      line-height: 22px;
      width: 100%;
      margin-top: 40px;
      cursor: pointer;

      &.small_trigger {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .right_section {
    height: 100%;
    width: calc(100% - 550px);
    background-color: #fafafa;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 22px;
    -webkit-border-top-left-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
}

.sm-header-title {
  font-size: 24px;
  font-weight: bold;
  color: #c4c4c4;

  &:hover {
    color: #000099;
  }

  .dark_text {
    color: #212121;
  }

  i {
    color: #000099;
    position: relative;
    top: 4px;
  }

  .pointer {
    cursor: pointer !important;
  }
}

@keyframes exampleWidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.modal-content {
  border-radius: 30px !important;
}

.chapter_list {
  margin-top: 40px;

  .chapter_list_item {
    * {
      transition: all 0.2s ease-in-out;
    }

    margin: 8px 0px;

    .chapter_item_header {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #c4c4c4;

      &:hover,
      &:focus,
      &:active {
        color: #ffeb3b;
      }

      .delete_section {
        color: #b71c1c !important;
        cursor: pointer;
      }
    }

    .chapter_item_body {
      padding: 20px;
      background: rgba(0, 0, 0, 0.3);

      .chapter_item_body_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5px;
        color: #c4c4c4;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 10px;
        background: rgba(255, 255, 255, 0.1);
        padding: 4px 10px;
        border-radius: 5px;

        &:hover,
        &:focus,
        &:active {
          color: #ffeb3b;
        }

        .rr_icon {
          position: relative;
          top: 1px;
          margin-right: 5px;
        }

        .cib_icon {
          position: relative;
          z-index: 99;
        }
      }
    }
  }
}

.swal2-container {
  z-index: 999999 !important;
}

.section_chapter_add_big {
  background-color: #f0f0f1;
  height: 170px;
  border: 1px solid #dddddd;
  border-radius: 30px;
  padding: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-link-chapter {
  color: #000099;
  font-size: 30px;
  border: none;
}

.selected_file_viewer {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: 500px;
}

// Full fixed div for active client
.activatedUserModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.69);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.2px);
  -webkit-backdrop-filter: blur(7.2px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lottiefy_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.course_video_item {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  position: relative;

  .component_selected {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 2px solid #33b3f2;
    border-radius: 10px;

    .checked_icon {
      color: #06d755;
      position: relative;
      left: 5px;
      top: 0px;
      font-size: 30px;
      z-index: 99;
    }
  }

  &:hover {
    background-color: #f7f7f7;
  }
}

.video_uploader_container {
  background-color: #f0f0f1;
  padding: 10px;
  border-radius: 10px;
}

.login_title {
  font-size: 30px !important;
  font-weight: bold !important;
  line-height: 40px;
  letter-spacing: normal;
}

.form-horizontal {
  .form-control,
  .opt_input input[type="text"] {
    border-radius: 10px !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: normal;
    border: 2px solid transparent;
    background-color: rgba(5, 6, 15, 0.04);
    transition: all 0.3s ease-in-out;
    height: 50px;

    &:hover,
    &:active {
      border-color: #000000 !important;
    }
  }
}

.authentication-bg {
  -webkit-border-top-left-radius: 30px;
  -webkit-border-bottom-left-radius: 30px;
  -moz-border-radius-topleft: 30px;
  -moz-border-radius-bottomleft: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  overflow: hidden;
  position: relative;
}

.eye_button {
  position: absolute;
  right: 10px;
  top: 41px;
  font-size: 22px;
  cursor: pointer;
}

.opt_input {
  margin: 0 -7px;
}

.ck-editor__main {
  max-height: 300px;
  overflow: auto;
}

.row_actions {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .row_actions_item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 2px 10px;
    background: #f1f1f1;
    border-radius: 5px;

    span {
      font-size: 80%;
      margin-left: 3px;
    }
  }
}

.pointer {
  cursor: pointer !important;
}

.btn-danger-outline {
  border-color: #ff3d60 !important;
  color: #ff3d60 !important;
  transition: all 0.4s ease-in-out;
  background: #f7f7f7;

  &:hover {
    background-color: #ff3d60 !important;
    color: #ffffff !important;
  }
}

.custom_alert {
  background-color: #664efd;
  color: #ffffff;
  padding: 20px 40px;
  position: fixed !important;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  border-radius: 10px;
  display: flex;
  transition: all 0.4s ease-in-out;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);

  &.success {
    background-color: #00c851;
  }

  .custom_alert_close {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 30px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    transition: all 0.4s ease-in-out;
    border-radius: 50%;
    position: relative;
    right: -10px;
  }
}

.custom_alert_content {
  cursor: pointer;
  font-size: 16px;
}

.mm-active,
body[data-sidebar="dark"] .mm-active {
  color: #f8db46 !important;

  a.active {
    color: #f8db46 !important;

    i {
      color: #f8db46 !important;
    }
  }

  // .mm-active{
  //   i{
  //     color: #f8db46 !important;
  //   }
  // }
}

.permission_chip {
  background: $primary;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0px 10px;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.question_card_new {
  position: relative;
  background: #ffffff;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 20px;
  display: block;

  .card_header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  .card_options {
    position: relative;

    .que_row {
      padding: 10px 15px;
      margin-bottom: 10px;
      border: 1px solid #09d3f1;
      background: rgba(9, 211, 241, 0.05);
      border-radius: 10px;
      position: relative;

      &.active {
        border: 1px solid #26cd05;
        background: rgba(38, 205, 5, 0.2);
        padding-right: 30px;

        .currect_icon {
          position: absolute;
          right: 10px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-radius: 50%;
          color: #26cd05;
          top: 50%;
          transform: translateY(-50%);
          -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.react-tagsinput-remove {
  color: #ffffff !important;
}

.custom_emails {
  .react-tagsinput-input {
    width: auto !important;
  }
}

.schedule_element_wrapper {
  background: #f9f9f9;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

.sch_icon {
  width: 30px;
  display: inline-block;

  i {
    position: relative;
    top: 1px;
    color: #4caf50;

    &.schedule {
      color: #ffeb3b;
    }
  }
}

.courses_wrapper {
  .pagination {
    li {
      background: #ffffff;
      border-color: #dddddd;
      a {
        color: #000000;
        opacity: 1;
      }
      &.active {
        border-color: #1890ff !important;
      }
    }
  }
}
