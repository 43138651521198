//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";
//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";
// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";
.accordian-bg-products {
  background-color: $gray-300;
}

.nav-link {
  cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}
.progress_container {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  .progress_container_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .CircularProgressbar {
      width: 120px;
    }
  }
}

.testimonials_iimg {
  .course_image_uploader {
    height: 220px;
    img {
      min-height: 220px;
    }
  }
}
.custom_selector .ant-select-selector {
  height: auto !important;
}
.discusstion_courses_wrapper {
  border-right: 1px solid #eee;
  height: 100%;
}
.discussion_list {
  border: 1px solid #dddddd;
  min-height: 300px;
  .discussion_item {
    padding: 20px 10px;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    margin-bottom: -1px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    &:hover,
    &.active {
      background-color: #eeeeee;
      font-weight: bold;
    }
  }
}
.discussion_details {
  padding: 10px;
  border: 1px solid #dddddd;
  .course_details_wrapper {
    border-bottom: 1px solid #dddddd;
    padding: 10px;
    display: flex;
    align-items: center;
    .course_image {
      width: 100px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .course_title {
      padding-left: 15px;
      h4 {
        font-size: 18px !important;
      }
    }
  }
  .comment_wrapper {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    .comment_user {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      .comment_user_image {
        width: 40px;
        height: 40px;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        img {
          width: 100%;
        }
      }
    }
    .comment {
      margin-top: 15px;
    }
  }
}
.message-list li .col-mail-2 .date {
  white-space: nowrap !important;
}
.active_column {
  background: rgba(54, 192, 35, 0.2) !important;
}
.small_tittle {
  font-size: 16px !important;
}
.img_wrapper_square {
  width: 300px;
  height: 158px;
  border: 3px dashed #eeeeee;
  border-radius: 15px;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #eeeeee;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.image_upload_wrapper_square {
  position: relative;
  overflow: hidden;
}
.image_upload_wrapper_square > input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}

.search_course_wrapper {
  position: relative;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 10px;
  top: -25px;
  .sb_btn {
    position: absolute;
    top: 45px;
    right: 25px;
    z-index: 999;
    font-size: 20px;
    cursor: pointer;
    &.spin {
      animation: spinIndicator 2s linear infinite;
    }
  }
}

@keyframes spinIndicator {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
